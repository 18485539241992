/* RotatingCDIcon.css */
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating-icon {
    animation: rotate 2s linear infinite; 
    width: 100px; 
    height: 40px; 
    /* position: absolute;  */
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); 
    z-index: -1;
    margin-right: 10px;
}
