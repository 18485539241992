.link:hover{
    color: var(--highlighted-color-light);
}
.link{
    text-decoration: none;
    padding:2;
    font-size:20px;
    font-weight:500;
    text-decoration: none;
    color:var(--primary-text-color)
}