.btn{
    border-color: var(--highlighted-color) !important;
    color:var(--primary-btn-color) !important;
    padding: 20px 40px !important;

}
.btn:hover{
    background-color: var(--highlighted-color) !important;
    color:var(--primary-btn-filled) !important;
}
.btnFilled{
    background-color: var(--highlighted-color) !important;
    color:var(--primary-btn-filled) !important;
    padding: 20px 40px !important;
}
.btnFilled:hover{
    background-color: var(--highlighted-color-light) !important;
}