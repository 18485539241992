/* @import url('https://fonts.googleapis.com/css2?family=Spartan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@400;700&display=swap');
html {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  background: #c9ced3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Spartan, sans-serif;
  font-size: 12px;
  background-image: url("https://wallpaper.dog/large/10785472.jpg");
 
} */


.player-container {
  /* height: 600px;
  width: 400px; */
  background: #abbfc8;
  height: 100vh;
  /* border-radius: 20px; */
  /* box-shadow: 0 15px 30px 5px rgba(0, 0, 0, 0.3); */
  /* margin-top: 60px; */
}

.img-container {
  height: 270px;
  width: 270px;
  margin: auto;
  position: relative;
  top: 50px;
  /* left: 50px; */
}

.img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.5);
}
.data{
  margin-top: 70px;
}

.title {
  font-size: 35px;
  text-align: center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.artist {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  margin: 5px 0 0;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

/* Progress */
.progress-container {
  border-radius: 5px;
  cursor: pointer;
  margin: 40px 20px;
}

.progress {
  background: #242323;
  border-radius: 5px;
  height: 100%;
  width: 0%;
  transition: width 0.1s linear;
}

.duration-wrapper {
  position: relative;
  top: -25px;
  display: flex;
  justify-content: space-between;
}

/* Controls */
.player-controls {
  display: flex;
  position: relative;
  width: 310px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.fas {
  font-size: 30px;
  color: rgb(129, 129, 129);
  cursor: pointer;
  user-select: none;
}

.fas:hover {
  filter: brightness(80%);
}

.main-button {
  font-size: 40px;
  position: relative;
  top: 3px;
}

/* Media Query: iPhone (Vertical) */
@media screen and (max-width: 430px) {
  .player-container {
    width: 100vw;
    height: 100vh;
    margin-top: 0px;
    /* padding-top: 40px; */
  }
  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 15px;
  }

  .player-controls {
    top: -10px;
    /* left: 100px; */
  }
}