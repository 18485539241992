:root {
  --primary-bg-color: #161815;
  --primary-text-color:#a4a4a4;
  --highlighted-color:#6DE754;
  --highlighted-color-light:#94e187;
  --primary-btn-color:#0f0f0f;
  --primary-btn-filled:#fff;
  --card-color:#7a7a7a;
  --main-card-color:#2d2d2d;
  --stepper-accent-color: #6DE754 !important;
  --inverted-color:rgb(237, 236, 236);
  --box-shadow:rgba(255, 255, 255, 0.16) 0px 3px 6px, rgba(255, 255, 255, 0.23) 0px 3px 6px
  --light-bg-color:#030303c9
}
/* Slick carousel styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.App {
  text-align: center;
  background-color: var(--primary-bg-color);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  user-select: none;
}
#waveform > div:nth-child(1) {
  display: none; /* Hide the second child */
}

  .main-body::-webkit-scrollbar {
    width: 6px;
  }
  
  .main-body::-webkit-scrollbar-track {
    background: var(--primary-bg-color) !important;
  }
  
  .main-body::-webkit-scrollbar-thumb {
    background:var(--highlighted-color) !important;
    border-radius: 7px;
  
  }


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--primary-text-color);
    transition: background-color 5000s ease-in-out 0s;
    /* box-shadow: inset 0 0 20px 20px #23232329; */
}
.chakra-step__indicator{
  border-color: var(--highlighted-color)
}
.chakra-step[data-status="active"] .chakra-step__indicator{
  border-color: var(--highlighted-color)
}

.chakra-step[data-status="complete"] .chakra-step__indicator{
  background: var(--highlighted-color);
  color:black
}
.chakra-step[data-status="complete"] .chakra-step__separator{
  background:var(--highlighted-color)
}
.chakra-input{
  border-width: 2px !important;
  border-color: var(--highlighted-color-light) !important;
}
.chakra-input:focus-visible {
  border-color: var(--highlighted-color) !important;
  border-width: 2px;
  box-shadow: none !important;
}
.chakra-select{
  padding: 12px 12px !important;
  color: var(--primary-text-color) !important;
  height: auto !important;
  border-width: 2px !important;
  border-color: var(--highlighted-color-light) !important;
}
.chakra-select >option{
  background: var(--primary-bg-color) !important;
}

.chakra-select:focus-visible {
  border-color: var(--highlighted-color) !important;
  border-width: 2px;
  box-shadow: none !important;
  border-width: 2px;
}
.chakra-input__left-addon{
  padding: 18px 14px;
  font-size: 18px;
  border-width: 2px;
}
.chakra-textarea {
  border-width: 2px !important;
  border-color: white;
}
.chakra-textarea[data-focus-visible] {
  border-color: var(--highlighted-color-light) !important;
  border-width: 2px;
  box-shadow: none !important;
}
.chakra-alert{
  color: black;
}
.chakra-modal__content-container{
  align-items: center !important;
}
/* #slider-track-slider{
  background-color:red
} */